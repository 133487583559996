(function ($) {
    $(document).ready(function () {
        var button = $('.mobile-sidebar');
        var sidebar = $('.consumer-products-sidebar');
        var close = $('.consumer-products-sidebar .close');

        button.click(function () {
            sidebar.toggleClass('mobile-sidebar-open');
        });

        close.click(function () {
            sidebar.toggleClass('mobile-sidebar-open');
        });
    });
})(jQuery);
